import classNames from 'classnames';
import { DistrictContext } from 'components/contexts/DistrictContext';
import {
  AnalyticsIcon,
  CloseIcon,
  CogAltIcon,
  DashboardIcon,
  FreeAppsIcon,
  HamburgerIcon,
  I18n,
  Navigation,
  PaidAppsIcon,
  ShieldCheckIcon,
} from 'design-system';
import corporateLogo from 'images/corporate-logo.svg';
import React, { useContext } from 'react';
import { Link, NavLink, useLocation, useParams } from 'react-router-dom';

import logoMobile from '../../../images/logo-app-analytics-2020-mobile.svg';
import { UserActionsContext } from '../../contexts/UserActionsContext';
import useAuthUser from '../../hooks/useAuthUser';
import Avatar from '../Avatar/Avatar';
import Icon from '../Icon';
import styles from './Sidebar.module.scss';

const privacyLink = 'https://www.lightspeedsystems.com/privacy/';

const NavSidebar = () => {
  const { guid } = useParams();
  const { mobileMenuState, mobileMenuHandler } = useContext(UserActionsContext);
  const { fullName, profilePicture, userEmail, readOnly } = useAuthUser();
  const { current } = useContext(DistrictContext);
  const location = useLocation();

  const getGuid = () => {
    const path = location.pathname.split('/');
    if (path[2] === 'apps') {
      return localStorage.getItem('selectedGroup');
    }
    return current || guid;
  };

  const districtGuid = getGuid();

  const closeMobileNav = () => {
    if (mobileMenuState) {
      mobileMenuHandler(false);
    }
  };

  const signOut = () => {
    closeMobileNav();
    delete localStorage.jwt;
    window.location.reload();
  };

  const user = { name: fullName, email: userEmail };

  const detectedAppItem = {
    name: I18n.t('app_manager'),
    path: '/apps/detected',
    iconMobile: 'cog',
    component: (props) => (
      <Link {...props} to={`/${districtGuid}/apps/detected`}>
        <CogAltIcon />
      </Link>
    ),
  };

  const navItems = [
    {
      name: I18n.t('dashboard'),
      path: '/dashboard',
      iconMobile: 'dashboard',
      component: (props) => (
        <Link {...props} to={`/${districtGuid}/dashboard`}>
          <DashboardIcon />
        </Link>
      ),
    },
    {
      name: I18n.t('paid_apps'),
      path: '/paid-apps',
      iconMobile: 'paid-apps',
      component: (props) => (
        <Link {...props} to={`/${districtGuid}/paid-apps`}>
          <PaidAppsIcon />
        </Link>
      ),
    },
    {
      name: I18n.t('free_apps'),
      path: '/free-apps',
      iconMobile: 'free-apps',
      component: (props) => (
        <Link {...props} to={`/${districtGuid}/free-apps`}>
          <FreeAppsIcon />
        </Link>
      ),
    },
    {
      name: I18n.t('compliance'),
      path: '/data-compliance',
      iconMobile: 'compliance',
      component: (props) => (
        <Link {...props} to={`/${districtGuid}/data-compliance`}>
          <ShieldCheckIcon />
        </Link>
      ),
    },
    {
      name: I18n.t('chart_builder'),
      path: '/chart-builder',
      iconMobile: 'analytics',
      component: (props) => (
        <Link {...props} to={`/${districtGuid}/chart-builder`}>
          <AnalyticsIcon />
        </Link>
      ),
    },
  ];

  if (!readOnly) {
    navItems.splice(1, 0, detectedAppItem);
  }

  const navItemsMobile = () => {
    return navItems.map(({ name, path, iconMobile }) => (
      <NavLink
        key={path}
        to={`/${districtGuid}${path}`}
        className={styles.link}
        activeClassName={styles.linkActive}
        onClick={closeMobileNav}
      >
        <Icon name={`icon-${iconMobile}`} classes='fontSize--24 m-right--16' />
        <span className={styles.linkText}>{name}</span>
      </NavLink>
    ));
  };

  const mobileSidebarHeader = () => (
    <div className={styles.mobileSidebarHeader}>
      <button
        onClick={() => mobileMenuHandler(!mobileMenuState)}
        className={classNames('button--link', styles.menuIcon)}
        data-testid='test-menu-button'
      >
        {mobileMenuState ? <CloseIcon /> : <HamburgerIcon />}
      </button>
      <Link onClick={closeMobileNav} to='/'>
        <img
          src={logoMobile}
          alt={I18n.t('lightspeed_analytics')}
          className={styles.lightspeedLogoMobile}
        />
      </Link>
    </div>
  );

  const mobileSubMenu = () => (
    <nav className={styles.subMenuContainer}>
      <div className={classNames(styles.link, styles.avatarContainer)}>
        <Avatar className='m-right--16' />
        <span className={styles.linkText}>{fullName}</span>
      </div>
      <a
        rel='noopener noreferrer'
        target='_blank'
        title={I18n.t('help')}
        className={styles.link}
        href={process.env.REACT_APP_ANALYTICS_HELP_URL}
      >
        <span className={styles.linkText}>{I18n.t('help')}</span>
      </a>
      <a
        target='_blank'
        href={privacyLink}
        title={I18n.t('privacy_policy')}
        rel='noopener noreferrer'
        className={classNames(styles.link)}
      >
        <span className={styles.linkText}>{I18n.t('privacy_policy')}</span>
      </a>
      <a href='#' className={styles.link} onClick={signOut}>
        <span className={styles.linkText}>{I18n.t('sign_out')}</span>
      </a>
    </nav>
  );

  return (
    <>
      <Navigation
        user={user}
        helpUrl={process.env.REACT_APP_ANALYTICS_HELP_URL}
        navItems={navItems}
        signOutHandler={signOut}
        avatarHandler={profilePicture}
        className={styles.dsNavigationStyles}
        currentMenu={
          navItems.find((item) => location.pathname.includes(item?.path))?.name
        }
        logoWrapper={({ children }) => (
          <Link to={`/${districtGuid}/dashboard`}>{children}</Link>
        )}
      />
      <div className={styles.mobileNavVisibility}>
        <section className={styles.mobileSidebar}>
          {mobileSidebarHeader()}
          <div
            className={classNames(styles.mobileNavContainer, {
              [styles.menuActive]: mobileMenuState,
            })}
          >
            <nav className={styles.mobileNav}>{navItemsMobile()}</nav>
            {mobileSubMenu()}
            <img className={styles.corporateLogo} src={corporateLogo} />
          </div>
        </section>
      </div>
    </>
  );
};

export default NavSidebar;
