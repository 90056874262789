/* eslint-disable multiline-ternary */
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';

import Layout from '../components/common/Layout';
import { AnalyticsProvider } from './contexts/AnalyticsContext';
import { DistrictProvider } from './contexts/DistrictContext';
import { UserContext } from './contexts/UserContext';

const PrivateRoute = ({
  component: Component,
  computedMatch,
  readOnly,
  ...restProps
}) => {
  const userContext = useContext(UserContext);
  const isAuthenticated = Boolean(userContext.user);
  const redirectUrl = !isAuthenticated && computedMatch && computedMatch.url;
  const isReadOnly = readOnly && userContext?.user?.readOnly;

  return (
    <Route
      {...restProps}
      render={(props) =>
        isAuthenticated && !isReadOnly ? (
          <DistrictProvider computedMatch={computedMatch}>
            <AnalyticsProvider>
              <Layout>
                <Component {...props} />
              </Layout>
            </AnalyticsProvider>
          </DistrictProvider>
        ) : (
          <Redirect to={{ pathname: '/', state: { from: redirectUrl } }} />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  computedMatch: PropTypes.object,
  location: PropTypes.object,
  readOnly: PropTypes.bool,
};

export default PrivateRoute;
