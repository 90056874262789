/* eslint-disable no-console */
import endOfDay from 'date-fns/endOfDay';
import getUnixTime from 'date-fns/getUnixTime';
import subDays from 'date-fns/subDays';
import { utcToZonedTime } from 'date-fns-tz';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { AnalyticsAPI } from '../../utilities/AnalyticsAPI';
import { sortBy } from '../../utilities/sort';

export const DistrictContext = React.createContext();
export const DistrictConsumer = DistrictContext.Consumer;
export class DistrictProvider extends Component {
  constructor(props) {
    super(props);

    // check for and gather favorite groups
    const savedGroups = JSON.parse(localStorage.getItem('savedGroups'));
    const savedDateRange = JSON.parse(localStorage.getItem('savedDateRange'));
    const savedAppDetailsGroup = JSON.parse(localStorage.getItem('appDetailsGroup'));

    this.state = {
      current: null,
      customerId: null,
      appDetailsGroup: savedAppDetailsGroup,
      districts: [],
      getCurrent: this.getCurrent.bind(this),
      setAppDetailsGroup: this.setAppDetailsGroup.bind(this),
      getCurrentDistrict: this.getCurrentDistrict.bind(this),
      setCurrent: this.setCurrent.bind(this),
      resetCurrent: this.resetCurrent.bind(this),
      setDateRange: this.setDateRange.bind(this),
      getAnalyticsOptions: this.getAnalyticsOptions.bind(this),
      getDashboardOptions: this.getDashboardOptions.bind(this),
      getComparisonDateRange: this.getComparisonDateRange.bind(this),
      getLast7DaysDateRange: this.getLast7DaysDateRange.bind(this),
      getLast30DaysDateRange: this.getLast30DaysDateRange.bind(this),
      getLast90DaysDateRange: this.getLast90DaysDateRange.bind(this),
      removeSavedGroup: this.removeSavedGroup.bind(this),
      addSavedGroup: this.addSavedGroup.bind(this),
      savedGroupsIncludes: this.savedGroupsIncludes.bind(this),
      savedGroups: savedGroups || [],
      savedDateRange: savedDateRange || {},
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (!props.computedMatch || !props.computedMatch.params) {
      return null;
    }

    const groupExists = [...state.districts, ...state.savedGroups].some(
      (district) => district.guid === props.computedMatch.params.guid
    );

    if (groupExists) {
      return {
        ...state,
        current: props.computedMatch.params.guid,
      };
    }

    return null;
  }

  componentDidMount() {
    this.getDistricts();
  }

  async getDistricts() {
    try {
      const data = await AnalyticsAPI.get('/districts');
      this.setState({
        districts: data.results,
        customerId: data.results[0].customer_id,
      });
    } catch (e) {
      console.log(e);
    }
  }

  setCurrent(guid) {
    this.setState({ current: guid });
  }

  setDateRange(dateRange) {
    this.setState({ savedDateRange: { type: dateRange } });
  }

  setAppDetailsGroup(group) {
    localStorage.setItem('appDetailsGroup', JSON.stringify(group));
    this.setState({ appDetailsGroup: group });
  }

  getCurrent(withIndex) {
    if (!this.state.current) {
      return;
    }

    let index;
    const current = [...this.state.districts, ...this.state.savedGroups].find(
      (d, i) => {
        index = i;
        return d.guid === this.state.current;
      }
    );
    return withIndex ? { current, index } : current;
  }

  getCurrentDistrict() {
    return this.state.districts.find(
      (district) => district.customer_id === this.state.customerId
    );
  }

  resetCurrent() {
    this.setState({ current: null });
  }

  removeSavedGroup(guid) {
    const newGroups = this.state.savedGroups.filter(
      (group) => group.guid !== guid
    );
    // save to local storage
    localStorage.setItem('savedGroups', JSON.stringify(newGroups));
    this.setState({ savedGroups: newGroups });
  }

  addSavedGroup(group) {
    const newGroups = this.state.savedGroups.concat(group);
    const sortedGroups = sortBy(newGroups, 'name', 'asc');
    // save to local storage
    localStorage.setItem('savedGroups', JSON.stringify(sortedGroups));
    this.setState({ savedGroups: sortedGroups });
  }

  savedGroupsIncludes(guid) {
    if (!guid) {
      return;
    }
    const item = this.state.savedGroups.find((group) => group.guid === guid);
    return Boolean(item);
  }

  getAnalyticsOptions() {
    const group = this.getCurrent();
    const dateRange = this.getLast30DaysDateRange();
    const currentDistrict = this.getCurrentDistrict();
    const timeZone = currentDistrict && currentDistrict.time_zone;
    return {
      start: dateRange.start,
      end: dateRange.end,
      guid: group && group.guid,
      customerId: this.state.customerId,
      timeZone,
    };
  }

  getDashboardOptions(isComparison = false) {
    const group = this.getCurrent();
    const currentDistrict = this.getCurrentDistrict();
    const timeZone = currentDistrict && currentDistrict.time_zone;
    let dateRange;
    let frequency = 30;
    if (!isComparison) {
      if (this.state.savedDateRange && this.state.savedDateRange.type) {
        switch (this.state.savedDateRange.type) {
          case '7Days':
            dateRange = this.getLast7DaysDateRange();
            frequency = 7;
            break;
          case '30Days':
            dateRange = this.getLast30DaysDateRange();
            frequency = 30;
            break;
          case '90Days':
            dateRange = this.getLast90DaysDateRange();
            frequency = 90;
            break;
          default:
            dateRange = this.getLast7DaysDateRange();
            break;
        }
      } else {
        dateRange = this.getLast7DaysDateRange();
      }
    } else {
      if (this.state.savedDateRange && this.state.savedDateRange.type) {
        dateRange = this.getComparisonDateRange(this.state.savedDateRange.type);
      } else {
        dateRange = this.getComparisonDateRange('7Days');
      }
    }
    return {
      start: dateRange.start,
      end: dateRange.end,
      guid: group && group.guid,
      customerId: this.state.customerId,
      timeZone,
      frequency,
    };
  }

  getYesterday() {
    const group = this.getCurrent();
    const today = utcToZonedTime(new Date(), group && group.time_zone);
    return endOfDay(subDays(today, 1));
  }

  getComparisonDateRange(compareisonRange) {
    const yesterday = this.getYesterday();
    let range;
    switch (compareisonRange) {
      case '7Days':
        range = 7;
        break;
      case '30Days':
        range = 30;
        break;
      case '90Days':
        range = 90;
        break;
      default:
        range = 7;
        break;
    }
    const endOfPreviousRange = endOfDay(subDays(yesterday, range));
    return {
      start: getUnixTime(subDays(endOfPreviousRange, range)),
      end: getUnixTime(endOfPreviousRange),
    };
  }

  getLast7DaysDateRange() {
    const yesterday = this.getYesterday();
    return {
      start: getUnixTime(subDays(yesterday, 7)),
      end: getUnixTime(yesterday),
    };
  }

  getLast30DaysDateRange() {
    const yesterday = this.getYesterday();
    return {
      start: getUnixTime(subDays(yesterday, 30)),
      end: getUnixTime(yesterday),
    };
  }

  getLast90DaysDateRange() {
    const yesterday = this.getYesterday();
    return {
      start: getUnixTime(subDays(yesterday, 90)),
      end: getUnixTime(yesterday),
    };
  }

  render() {
    return (
      <DistrictContext.Provider value={this.state}>
        {this.props.children}
      </DistrictContext.Provider>
    );
  }
}

DistrictProvider.propTypes = {
  children: PropTypes.object,
  computedMatch: PropTypes.object,
};
