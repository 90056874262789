export const initialState = {
  baseURL: '/dashboard/group_details',
  groups: undefined,
  selected: undefined,
  searchValue: '',
  filterOpen: false,
  selectedGroupTypes: undefined,
  rangeValues: { min: '', max: '' },
  userCount: undefined,
  isLoading: false,
  isLoadingPage: false,
  page: 1,
  lastPage: 0,
};

function reducer(state, action) {
  switch (action.type) {
    case 'setLoading': {
      return { ...state, isLoading: action.value };
    }
    case 'setGroups': {
      if (!action.groups) {
        return { ...state };
      }
      let groups = [...action.groups];
      if (state.page > 1) {
        groups = [...state.groups, ...groups];
      }

      // remove duplicate guids
      groups = groups.reduce((group, item) => {
        const removed = group.filter((i) => i.guid !== item.guid);
        return [...removed, item];
      }, []);
      return {
        ...state,
        groups: groups,
        userCount: action.count,
        isLoading: false,
        lastPage: action.lastPage,
      };
    }
    case 'setSelectedGroup': {
      return { ...state, selected: action.group, isLoading: false };
    }
    case 'resetSelectedGroup': {
      return { ...state, selected: undefined };
    }
    case 'setSearchValue': {
      return { ...state, searchValue: action.value };
    }
    case 'setURL': {
      let loading = true;
      let page = 1;
      let isLoadingPage;
      // first extract possible params from stored URL
      let params = state.url;
      if (params) {
        params = state.url.split('?');
        params = params.length > 1 ? params[1] : undefined;
      }
      // Create our search param object
      params = new URLSearchParams(params);
      params.delete('page'); // reset page each time
      if (action.param === 'query') {
        // 'query' is the general search.
        if (state.searchValue === '') {
          params.delete('query');
        } else if (state.searchValue.length === 1) {
          loading = false;
        } else if (state.searchValue.length > 1) {
          params.set('query', state.searchValue);
        }
      } else if (action.param === 'rangeValues') {
        // 'max_users' and 'min_users' filter
        if (state.rangeValues.min === '' && state.rangeValues.max === '') {
          params.delete('max_users');
          params.delete('min_users');
        } else {
          const min = state.rangeValues.min === '' ? 0 : state.rangeValues.min;
          const max =
            state.rangeValues.max === '' ? min : state.rangeValues.max;
          params.set('max_users', max);
          params.set('min_users', min);
        }
      } else if (action.param === 'groupTypes') {
        // 'group_types' filters
        // possible groups:
        //   OuGroup, MdmGroup, Group, GoogleClassroom, ManualClassroom, DirectoryGroup, RecordingGroup
        const nonOuGroups =
          'MdmGroup,Group,GoogleClassroom,ManualClassroom,DirectoryGroup,RecordingGroup';
        if (state.selectedGroupTypes.length === 0) {
          params.delete('group_types');
        } else {
          const current = [];
          if (state.selectedGroupTypes.includes('Ou')) {
            current.push('OuGroup');
          }
          if (state.selectedGroupTypes.includes('Group')) {
            current.push(nonOuGroups);
          }
          params.set('group_types', current.join(','));
        }
      } else if (action.param === 'nextPage' && state.page < state.lastPage) {
        // 'next_page' param
        page = state.page + 1;
        loading = false;
        params.set('page', page);
      } else if (action.param === 'nextPage' && state.page >= state.lastPage) {
        // 'next_page' param
        loading = false;
        isLoadingPage = false;
        page = state.lastPage;
      }

      return {
        ...state,
        url: `${state.baseURL}?${params.toString()}`,
        isLoading: loading,
        page: page,
        ...(typeof isLoadingPage !== undefined && { isLoadingPage }),
      };
    }
    case 'setRangeMinValue': {
      const minNumber = Number(action.value);
      const maxNumber = Number(state.rangeValues.max);
      const max = minNumber > maxNumber ? minNumber : maxNumber;
      return { ...state, rangeValues: { min: minNumber, max: max } };
    }
    case 'setRangeMaxValue': {
      const maxNumber = Number(action.value);
      const minNumber = Number(state.rangeValues.min);
      const min = maxNumber < minNumber ? maxNumber : minNumber;
      return { ...state, rangeValues: { max: maxNumber, min: min } };
    }
    case 'toggleFilterArea': {
      return { ...state, filterOpen: !state.filterOpen };
    }
    case 'toggleGroupTypeFilter': {
      let selectedTypes = state.selectedGroupTypes
        ? [...state.selectedGroupTypes]
        : [];
      // if the name is in the array, remove it otherwise add it
      if (selectedTypes.includes(action.name)) {
        const nameIndex = selectedTypes.indexOf(action.name);
        selectedTypes.splice(nameIndex, 1);
      } else {
        selectedTypes.push(action.name);
      }
      return {
        ...state,
        selectedGroupTypes: selectedTypes,
      };
    }
    case 'resetFilters': {
      return {
        ...state,
        selectedGroupTypes: [],
        rangeValues: {
          min: '',
          max: '',
        },
      };
    }
    case 'setIsLoadingPage': {
      const isLoadingPage =
        state.page === state.lastPage ? false : action.value;
      return { ...state, isLoadingPage };
    }
    default: {
      return state;
    }
  }
}

export default reducer;
