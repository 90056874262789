/* eslint-disable no-console */
import I18n from './i18n';

export const defaultHeaders = {
  'x-api-key': process.env.REACT_APP_ANALYTICS_API_KEY,
  'Content-Type': 'application/json',
};

const processErrors = (error) => {
  switch (error) {
    case 'bad roles':
      error = I18n.t('insufficient_privileges_please_contact_admin');
      break;
    case 'Bad JWT':
      error = I18n.t('login_link_has_expired');
      break;
    case 'not_found':
      error = I18n.t('the_username_password_you_entered_is_invalid');
      break;
    case 'unauthenticated':
      error = I18n.t('the_username_password_you_entered_is_invalid');
      break;
    case 'invalid username / password':
      error = I18n.t('the_username_password_you_entered_is_invalid');
      break;
    default:
      error = I18n.t('an_unknown_error_occured_please_contact_admin');
      break;
  }

  return { error };
};

const responseState = {
  QUEUED: 'QUEUED',
  RUNNING: 'RUNNING',
  RETRY: 'RETRY',
  SUCCESS: 'SUCCESS',
};

const defaultEvents = {
  onInit: (endpoint) => endpoint,
  onQueued: (response, endpoint) => `${endpoint}&queryExecutionId=${response.queryExecutionId}`,
  onRetry: (response, endpoint) => endpoint,
  onSuccess: (response) => response,
};

export const AnalyticsAPI = {
  recursiveFetch(url, options = {}, events = defaultEvents) {
    const seconds = 1000;

    return new Promise(async (resolve, reject) => {
      try {
        let endpoint = url;

        if (events.onInit && typeof events.onInit === 'function') {
          const newUrl = events.onInit(endpoint);
          endpoint = newUrl || endpoint;
        }

        const response = await this.get(endpoint, options);

        if ([responseState.QUEUED, responseState.RUNNING].includes(response.state)) {
          setTimeout(() => {
            if (events.onQueued && typeof events.onQueued === 'function') {
              const newUrl = events.onQueued(response, endpoint);
              endpoint = newUrl || endpoint;
            }

            resolve(this.recursiveFetch(endpoint, options, events));
          }, response.retryInSecs * seconds);
        } else if (response.state === responseState.RETRY) {
          setTimeout(() => {
            if (events.onRetry && typeof events.onRetry === 'function') {
              const newUrl = events.onRetry(response, endpoint);
              endpoint = newUrl || endpoint;
            }

            resolve(this.recursiveFetch(endpoint, options, events));
          }, response.retryInSecs * seconds);
        } else {
          if (events.onSuccess && typeof events.onSuccess === 'function') {
            events.onSuccess(response);
          }
          resolve(response);
        }
      } catch (err) {
        if (events.onError) {
          events.onError(err);
        }

        reject(err);
      }
    });
  },
  async get(url, options = {}) {
    try {
      const response = await fetch(`${process.env.REACT_APP_ANALYTICS_API_URL}${url}`, {
        method: 'get',
        headers: {
          ...defaultHeaders,
          jwt: localStorage.jwt,
          ...options.headers,
        },
      });
      if (response.status >= 400) {
        throw new Error(await response.text());
      }
      return response.json();
    } catch (error) {
      console.log(error);
    }
  },

  async post(url, data, options = {}) {
    try {
      const response = await fetch(`${process.env.REACT_APP_ANALYTICS_API_URL}${url}`, {
        method: 'post',
        headers: {
          ...defaultHeaders,
          jwt: localStorage.jwt,
          ...options.headers,
        },
        body: JSON.stringify(data),
      });
      if (response.status >= 400) {
        throw new Error(await response.text());
      }
      return response.json();
    } catch (error) {
      throw error;
    }
  },

  async put(url, data, options = {}) {
    try {
      const response = await fetch(`${process.env.REACT_APP_ANALYTICS_API_URL}${url}`, {
        method: 'put',
        headers: {
          ...defaultHeaders,
          jwt: localStorage.jwt,
          ...options.headers,
        },
        body: JSON.stringify(data),
      });
      return response.json();
    } catch (error) {
      console.error(error);
      return {};
    }
  },

  async delete(url, data, options = {}) {
    try {
      const response = await fetch(`${process.env.REACT_APP_ANALYTICS_API_URL}${url}`, {
        method: 'delete',
        headers: {
          ...defaultHeaders,
          jwt: localStorage.jwt,
          ...options.headers,
        },
        body: JSON.stringify(data),
      });
      if (response.status >= 400) {
        throw new Error(await response.text());
      }
      return response.json();
    } catch (error) {
      console.error(error);
      return {};
    }
  },

  async authenticateWithSSO(type, code) {
    const authUrl = type === 'azure' ? '/auth/azure_callback' : '/auth/google_callback';

    try {
      const response = await fetch(`${process.env.REACT_APP_ANALYTICS_API_URL}${authUrl}`, {
        method: 'post',
        headers: {
          ...defaultHeaders,
        },
        body: JSON.stringify({
          code: code,
          redirect_url: process.env.REACT_APP_ANALYTICS_URL,
        }),
      });

      let data = await response.json();

      if (data.error) {
        if (data.error.customMessage) {
          return processErrors(data.error.customMessage);
        }
        return processErrors(data.error);
      }

      return data;
    } catch (error) {
      console.error(error);
    }
  },

  async validateJWT(jwt) {
    if (jwt) {
      localStorage.setItem('jwt', jwt);
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_ANALYTICS_API_URL}/auth/jwt_check`, {
        method: 'get',
        headers: {
          ...defaultHeaders,
          jwt: localStorage.jwt,
        },
      });
      const text = await response.text();
      if (text === 'Bad JWT') {
        delete localStorage.jwt;
        return processErrors(text);
      }
      return JSON.parse(text);
    } catch (error) {
      console.error(error);
      if (error.response && error.response.data === 'Bad JWT') {
        delete localStorage.jwt;
        return processErrors(error);
      }
    }
  },

  async authenticate(username, password) {
    try {
      const response = await fetch(`${process.env.REACT_APP_ANALYTICS_API_URL}/auth`, {
        method: 'post',
        headers: new Headers({
          'x-api-key': process.env.REACT_APP_ANALYTICS_API_KEY,
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify({
          username,
          password,
        }),
      });

      let data = await response.json();

      if (data.error) {
        if (data.error.customMessage) {
          return processErrors(data.error.customMessage);
        }

        return processErrors(data.error);
      }

      return data;
    } catch (error) {
      console.error(error);
    }
  },
};
