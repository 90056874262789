import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useHistory } from 'react-router-dom';
const trackingId = process.env.REACT_APP_GOOGLE_ANALYTICS;

const GoogleAnalytics = ({ children }) => {
  const { location } = useHistory();
  ReactGA.initialize(trackingId);

  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, [location.pathname]);

  return children;
};

export default GoogleAnalytics;