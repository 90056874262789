import '../css/layout/_main_layout.scss';

import { HelpIcon } from 'design-system';
import React from 'react';
import { Helmet } from 'react-helmet';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { baseUrl, createQueryFn } from '../utilities/fetcher';
import I18n from '../utilities/i18n';
import {
  App,
  ApprovedAppsList,
  ChartBuilder,
  Dashboard,
  DataPrivacy,
  DataPrivacyDetails,
  DetectedApps,
  FreeApps,
  PaidAppsDashboard,
  PaidAppsTableView,
  ProcessSignIn,
  SignIn,
} from './asyncModules';
import Debug from './common/Debug';
import GoogleAnalytics from './common/GoogleAnalytics';
import { UserActionsProvider } from './contexts/UserActionsContext';
import { UserProvider } from './contexts/UserContext';
import PrivateRoute from './PrivateRoute';

const isProduction = process.env.REACT_APP_ENV === 'production';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: isProduction,
      retry: isProduction ? 2 : false,
      queryFn: createQueryFn(baseUrl),
    },
  },
});

const GuidRoutes = () => (
  <>
    <Helmet>
      {/* OneTrust Cookies Consent Notice start for analytics.relay.school */}
      <script
        src='https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'
        type='text/javascript'
        charset='UTF-8'
        data-domain-script='293abb78-7e94-449c-a2e4-45696b611fc9'
      />
    </Helmet>
    <UserProvider>
      <UserActionsProvider>
        <GoogleAnalytics>
          <QueryClientProvider client={queryClient}>
            <Switch>
              <PrivateRoute
                exact
                path='/signing-in'
                component={ProcessSignIn}
              />
              <PrivateRoute
                exact
                path='/:guid/dashboard'
                component={Dashboard}
              />
              <PrivateRoute
                exact
                path='/:guid/data-compliance'
                component={DataPrivacy}
              />
              <PrivateRoute
                exact
                path='/:guid/data-compliance/:app_guid'
                component={DataPrivacyDetails}
              />
              <PrivateRoute
                exact
                path='/:guid/apps/detected'
                component={DetectedApps}
                readOnly
              />
              <PrivateRoute
                exact
                path='/:guid/paid-apps'
                component={PaidAppsDashboard}
              />
              <PrivateRoute
                exact
                path='/:guid/paid-apps-list'
                component={PaidAppsTableView}
              />
              <PrivateRoute
                exact
                path='/:guid/free-apps'
                component={FreeApps}
              />
              <PrivateRoute
                exact
                path='/:guid/apps'
                component={PaidAppsTableView}
              />
              <PrivateRoute
                exact
                path='/:guid/apps/:app_guid'
                component={App}
              />
              <PrivateRoute
                exact
                path='/:guid/chart-builder'
                component={ChartBuilder}
              />
              {process.env.REACT_APP_ENV === 'development' && (
                <PrivateRoute exact path='/:guid/debug' component={Debug} />
              )}
              <Route path='/' component={SignIn} />
            </Switch>
            <ReactQueryDevtools />
          </QueryClientProvider>
        </GoogleAnalytics>

        <a
          rel='noopener noreferrer'
          target='_blank'
          title={I18n.t('help')}
          href={process.env.REACT_APP_ANALYTICS_HELP_URL}
        >
          <div className='help-bubble'>
            <HelpIcon />
          </div>
        </a>
      </UserActionsProvider>
    </UserProvider>
  </>
);

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path='/approved-apps' component={ApprovedAppsList} />
      <Route path='/' component={GuidRoutes} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
