import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import generateDashboardReport from '../views/Dashboard/report';

export const UserActionsContext = React.createContext();
export const UserActionsConsumer = UserActionsContext.Consumer;
export class UserActionsProvider extends Component {
  constructor(props) {
    super(props);
    this.pdfRef = React.createRef();
    this.state = {
      exporting: false,
      mobileMenuState: false,
      redirectOrigin: undefined,
      exportToPDF: this.exportToPDF.bind(this),
      mobileMenuHandler: this.mobileMenuHandler.bind(this),
      setRedirectOrigin: this.setRedirectOrigin.bind(this),
    };
  }

  mobileMenuHandler(isOpen) {
    this.setState({ mobileMenuState: isOpen });
  }

  setRedirectOrigin(origin) {
    this.setState({ redirectOrigin: origin });
  }

  async exportToPDF() {
    this.setState({ exporting: true });
    await generateDashboardReport();
    this.setState({ exporting: false });
  }

  render() {
    return (
      <Fragment>
        <div id='outsideElement' ref={this.pdfRef}></div>
        <UserActionsContext.Provider value={this.state}>{this.props.children}</UserActionsContext.Provider>
      </Fragment>
    );
  }
}

UserActionsProvider.propTypes = {
  children: PropTypes.object,
};
