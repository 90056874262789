import { stringifyQueryParams as qs } from './helper';
import { defaultHeaders } from './AnalyticsAPI';

export const baseUrl = process.env.REACT_APP_ANALYTICS_API_URL;

export const fetcher = (url = '', options = {}) => {
  return () => fetch(url, options).then((res) => res.json());
};

export const createQueryFn = (baseUrl) => {
  return async ({ queryKey }) => {
    const endpoint = queryKey[0];
    const options = queryKey[1] || {};

    const path = endpoint + qs(options);
    const res = await fetch(baseUrl + path, {
      headers: {
        ...defaultHeaders,
        jwt: localStorage.jwt,
        cid: options.customer_id,
      },
    });

    if (!res.ok) {
      throw new Error(await res.json());
    }

    return res.json();
  };
};

export const buildEndpoint = (endpoint, queryParams = {}) => {
  const params = qs(queryParams);
  return `${endpoint}${params}`;
};
