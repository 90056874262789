import React, { Fragment, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ChevronThickLeftIcon, ChevronThickRightIcon } from 'design-system';
import { DistrictContext } from '../../contexts/DistrictContext';
import I18n from '../../../utilities/i18n';
import styles from './index.module.scss';

const SelectedGroup = ({ group, save, getGroupByName, isLoading, hasResults }) => {
  const [selectedLoading, setSelectedLoading] = useState(false);
  const { getCurrentDistrict, savedGroupsIncludes } = useContext(DistrictContext);
  const district = getCurrentDistrict();
  const buttonClasses = classNames('button--link', [styles.breadcrumb]);
  const isFavorited = savedGroupsIncludes(group && group.guid);

  const groupClick = (groupName) => {
    setSelectedLoading(true);
    setTimeout(() => getGroupByName(groupName), 1000);
  };

  useEffect(() => {
    if (group) {
      setSelectedLoading(false);
    }
  }, [group]);

  if (isLoading || selectedLoading) {
    return <section className={styles.selectedSkeleton} aria-label={I18n.t('group_loading')} />;
  }

  return (
    <Fragment>
      {!isLoading && !group && (
        <p className={styles.noGroup}>
          <ChevronThickLeftIcon className='m-right--4' />
          {I18n.t('select_a_group')}
        </p>
      )}

      {group && !isLoading && (
        <div className='fadeIn'>
          <div className={styles.groupDetailsName}>
            <h3 className='fontSize--26'>{group.name}</h3>

            <span className='color--gray'>
              {I18n.t('users_count', { count: group.user_count || 0 })}
            </span>
          </div>

          {group.type && (
            <ul className='l-flex m-bottom--32'>
              <li className={styles.groupType}>{group.type}</li>
            </ul>
          )}

          <ul className={styles.breadcrumbs}>
            <li>
              <strong className='m-right--8'>{district.name}</strong>
            </li>

            {group.parents && group.parents.map((group) => (
              <li key={group.guid}>
                <ChevronThickRightIcon className={styles.breadcrumbIcon} />
                <button
                  className={buttonClasses}
                  onClick={() => groupClick(group)}
                >
                  {group.name}
                </button>
              </li>
            ))}

            <li>
              <ChevronThickRightIcon className={styles.breadcrumbIcon} />
              <span>{group.name}</span>
            </li>
          </ul>

          {group.child_groups && (
            <Fragment>
              <div className={styles.childGroups}>
                <h4 className={styles.childGroupsTitle}>
                  {I18n.t('child_groups_count', { count: group.child_groups.length })}
                </h4>

                {group.child_groups.length > 0 && (
                  <ul className={styles.childGroupsList}>
                    {group.child_groups.map((group) => (
                      <li
                        key={group.guid}
                        className={styles.childGroupsItem}
                        onClick={() => groupClick(group)}
                        role='button'
                        tabIndex='0'
                      >
                        {group.name}
                      </li>
                    ))}
                  </ul>
                )}
              </div>

              <button
                className='button'
                onClick={() => save(group)}
                disabled={isFavorited}
              >
                {isFavorited ? I18n.t('group_is_in_list') : I18n.t('add_group_to_list')}
              </button>
            </Fragment>
          )}
        </div>
      )}
    </Fragment>
  );
};

SelectedGroup.propTypes = {
  group: PropTypes.object,
  save: PropTypes.func,
  getGroupByName: PropTypes.func,
  isLoading: PropTypes.bool,
  hasResults: PropTypes.bool,
};

export default SelectedGroup;