import React from 'react';
import loadable from 'react-loadable';

// we use an emtpy loader since we already have a loader inside each view
const Loader = () => <></>;

export const SignIn = loadable({
  loader: () => import('./views/SignIn'),
  loading: Loader,
});

export const Dashboard = loadable({
  loader: () => import('./views/Dashboard'),
  loading: Loader,
});

export const ApprovedAppsList = loadable({
  loader: () => import('./views/SignIn/ProcessSignIn'),
  loading: Loader,
});

export const ProcessSignIn = loadable({
  loader: () => import('./views/SignIn/ProcessSignIn'),
  loading: Loader,
});

export const PaidAppsDashboard = loadable({
  loader: () => import('./views/PaidApps/Dashboard'),
  loading: Loader,
});

export const FreeApps = loadable({
  loader: () => import('./views/FreeApps'),
  loading: Loader,
});

export const PaidAppsTableView = loadable({
  loader: () => import('./views/PaidApps/TableView'),
  loading: Loader,
});

export const App = loadable({
  loader: () => import('./views/Apps'),
  loading: Loader,
});

export const DetectedApps = loadable({
  loader: () => import('./views/DetectedApps'),
  loading: Loader,
});

export const DataPrivacy = loadable({
  loader: () => import('./views/DataPrivacy'),
  loading: Loader,
});

export const DataPrivacyDetails = loadable({
  loader: () => import('./views/DataPrivacy/Details/Details'),
  loading: Loader,
});

export const ChartBuilder = loadable({
  loader: () => import('./views/ChartBuilder'),
  loading: Loader,
});
